<template>
  <div>
    <b-message type="is-dark">
      <apart class="is-paddingless">
        <p>Create a new promotion.</p>
        <button
          class="button is-dark is-rounded is-outlined"
          @click="addPromotion"
        >
          Create promotion
        </button>
      </apart>
    </b-message>

    <loading v-if="loading" />

    <div v-else>
      <b-table
        ref="table"
        :data="sortedPromotions"
        :mobile-cards="true"
        :detailed="true"
        :show-detail-icon="true"
        hoverable
        striped
        detail-key="_id"
        class="has-vertical-align-middle"
      >
        <template slot-scope="props">
          <b-table-column field="name" label="Name">
            <router-link
              :to="`/admin/settings/promotions/${props.row._id}`"
              class="has-text-dark"
            >
              <u>{{ props.row.name }}</u>
            </router-link>
          </b-table-column>

          <b-table-column label="Provision" width="150">{{
            props.row.provision
          }}</b-table-column>

          <b-table-column label="Uses" width="140"
            >{{ props.row.useCount }} / {{ props.row.useLimit }}</b-table-column
          >

          <b-table-column custom-key="edit" width="1">
            <button class="button" width="100" @click="edit(props.row)">
              Edit
            </button>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <p>{{ props.row.description }}</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "@firebase/firestore";
export default {
  name: "PromotionsTable",
  data() {
    return {
      loading: true,
      promotions: {},
      processing: false
    };
  },
  computed: {
    sortedPromotions() {
      return this.$_(this.promotions)
        .orderBy(["isActive", "dateCreated"], ["desc", "desc"])
        .value();
    }
  },
  created() {
    this.getPromotions();
  },
  methods: {
    edit(promotion) {
      this.$router.push({
        path: `/admin/settings/promotions/${promotion._id}`
      });
      //this.$emit('edit', promotion)
    },
    addPromotion() {
      const modal = this.$modal.open({
        component: () => import("@shared/promotions/_promotionModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        events: {
          success: () => {
            modal.close();
            this.getPromotions();
            //this.$router.push({path: `/admin/settings/promotions/${this.promotionId}`});
          }
        }
      });
    },
    getPromotions() {
      const ref = collection(this.$firestore, `promotions`);
      getDocs(ref)
        .then(snapshot => {
          snapshot.forEach(promotion => {
            this.$set(
              this.promotions,
              promotion.id,
              this.$_.merge({}, { _id: promotion.id }, promotion.data())
            );
          });

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    }
  }
};
</script>
